import { ChevronDownIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { MenuItemButton, MenuItemContainer } from './MenuItem';
import { classNames } from '../../utils';
import { Tooltip } from '../Tooltip';

export const ShowMoreMenuItem = (props) => {
  const { groupId, onClick, limit, selectedItems, total } = props;

  return (
    <MenuItemContainer isDisabled closeOnSelect={false}>
      <MenuItemButton
        onClick={onClick}
        weight={'solid'}
        noBorder
        className={
          'justify-between bg-gray-100 bg-opacity-25 hover:bg-opacity-50 hover:text-black text-[10px] font-medium py-1.5 my-0.5 text-black'
        }
      >
        <div className={'flex items-center gap-4'}>
          <span>{limit === -1 ? 'Show less' : `Show more (${total - limit})`}</span>
          {limit > 0 && selectedItems?.length ? (
            <div
              data-tooltip-id={`${groupId}-tooltip`}
              className={'w-1 h-1 bg-gray-200 flex justify-center items-center p-2 rounded-full'}
            >
              <span className={'text-black text-[10px] font-medium'}>{selectedItems.length}</span>
            </div>
          ) : null}
          {limit > 0 && selectedItems?.length ? (
            <Tooltip
              id={`${groupId}-tooltip`}
              positionStrategy={'fixed'}
              content={`${selectedItems.length} selected: ${selectedItems.map((item) => item.label).join(', ')}`}
            />
          ) : null}
        </div>
        <ChevronDownIcon
          className={classNames(
            'w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100 group-hover:text-gray-500',
            limit === -1 ? '-rotate-180' : ''
          )}
        />
      </MenuItemButton>
    </MenuItemContainer>
  );
};
