import React from 'react';
import { classNames } from '../../utils/classNames';
import { Icon } from '../Icon';

export interface DropdownLabelProps {
  label: React.ReactNode;
  icon?: React.ReactNode | React.FC<any>;
  prefix?: React.ReactNode;
}

export const DropdownLabel: React.FC<DropdownLabelProps> = (props) => {
  const { icon, prefix, label, isActive } = props;

  return (
    <>
      <Icon icon={icon} iconProps={{ className: 'h-4 w-4 mr-2', 'aria-hidden': 'true' }} />
      {prefix && (
        <span className={classNames('text-gray-200 mr-1', !isActive && 'text-gray-500')}>
          {prefix}
        </span>
      )}
      {!label || typeof label === 'string' ? (
        <span className={classNames('truncate', !label && 'opacity-0')}>{label || 'No value'}</span>
      ) : (
        label
      )}
    </>
  );
};
