import React from 'react';
import {
  ModalOverlay as BaseModalOverlay,
  ModalOverlayProps as BaseModalOverlayProps
} from 'react-aria-components';
import { ModalPosition } from './types';
import { classNames } from '../../utils/classNames';

export type ModalOverlayProps = BaseModalOverlayProps & {
  position?: ModalPosition;
};

export const ModalOverlay: React.FC<ModalOverlayProps> = ({ position, ...props }) => (
  <BaseModalOverlay
    {...props}
    className={({ isEntering, isExiting, state }) =>
      classNames(
        // To avoid z-index issues with dropdown menus
        'fixed inset-0 z-[100000] overflow-y-auto min-h-full',
        'flex text-center bg-gray-500/75 backdrop-blur-sm transition-opacity',
        // {
        //   'animate-in fade-in duration-150 ease-in-out': isEntering,
        //   'animate-out fade-out duration-150 ease-in': isExiting
        // },
        {
          'opacity-1 bg-gray-500/75 backdrop-blur-sm duration-300': state.isOpen,
          'opacity-0.2 bg-gray-500/0 backdrop-blur-0 duration-150': !state.isOpen || isEntering
        },
        {
          'justify-center items-center': position === 'center'
        },
        props.className
      )
    }
  />
);
