import React from 'react';
import { classNames } from '../../utils/classNames';
import { MenuSearchField, MenuSearchFieldProps } from '../Menu';

export type DropdownMenuSearchFieldProps = MenuSearchFieldProps & {
  suffix?: React.ReactNode;
};

export const DropdownMenuSearchField: React.FC<DropdownMenuSearchFieldProps> = (props) => {
  const { suffix, ...rest } = props;

  if (suffix) {
    return (
      <div className='flex items-center mx-2 gap-x-2'>
        <MenuSearchField
          {...rest}
          className={classNames('bg-white pt-2 pb-2 flex-1', rest.className)}
        />
        {suffix}
      </div>
    );
  }

  return <MenuSearchField {...rest} className={classNames('bg-white p-2 pb-0', rest.className)} />;
};
