import { classNames } from '../../../utils/classNames';
import { ButtonColor, ButtonWeight } from '../types';

export function getButtonViewClasses(props: {
  color?: ButtonColor;
  weight?: ButtonWeight;
  plain?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
  noBorder?: boolean;
}) {
  const { color, weight = 'solid', isDisabled, isActive, plain, noBorder } = props;

  const isSolid = weight === 'solid';
  const isOutline = weight === 'outline';
  const isGhost = weight === 'ghost';
  const isInline = weight === 'inline';

  const baseClasses = classNames(
    'text-dark-950',
    { 'border border-transparent': !noBorder },
    {
      'bg-dark-100 text-dark-400': isDisabled,
      'border-dark-100 bg-white': (isOutline || isGhost) && isDisabled,
      'border-[1.5px]': (isOutline || isGhost) && !noBorder && isDisabled,
      'border-transparent': isGhost && isDisabled,
      'bg-transparent': isInline && isDisabled
    },
    { 'border-0': plain }
  );

  if (plain || !color) {
    return classNames(baseClasses);
  }

  if (color === 'primary') {
    return classNames(
      baseClasses,
      '[aria-selected="true"]:bg-primary-600 [aria-selected="true"]:border-primary-600 [aria-selected="true"]:text-light-950',

      isActive
        ? {
            'bg-primary-600 text-light-950': isSolid && !isDisabled,
            'bg-primary-200 text-primary-800': (isOutline || isGhost) && !isDisabled,
            'text-primary-950': isInline && !isDisabled
          }
        : {
            'bg-primary-800 text-light-950': isSolid && !isDisabled,
            'border-primary-800': isSolid && !isDisabled,
            'border-primary-600': isOutline && !isDisabled,
            'bg-transparent': (isOutline || isGhost) && !isDisabled,
            'text-primary-800': (isInline || isGhost || isOutline) && !isDisabled,

            'data-[state="hovered"]:bg-primary-700 data-[state="hovered"]:border-primary-700 data-[state="hovered"]:text-light-950 hover:bg-primary-700 hover:border-primary-700 hover:text-light-950':
              isSolid && !isDisabled,
            'data-[state="hovered"]:bg-primary-100 data-[state="hovered"]:border-primary-100 hover:bg-primary-100 hover:border-primary-100':
              !isSolid && !isInline && !isDisabled,
            'data-[state="hovered"]:text-primary-900 hover:text-primary-900':
              isInline && !isDisabled
          }
    );
  }

  if (color === 'secondary') {
    return classNames(
      baseClasses,
      isActive
        ? {
            'bg-secondary-800 border-secondary-800 text-light-950': isSolid && !isDisabled,
            'bg-secondary-400 border-secondary-400 text-secondary-950':
              !isSolid && !isInline && !isDisabled,
            'text-dark-700': isInline && !isDisabled
          }
        : {
            'bg-secondary-600 border-secondary-600 text-light-950': isSolid && !isDisabled,
            'bg-transparent text-dark-950': !isSolid && !isDisabled,
            'border-dark-200': isOutline && !isDisabled,

            'data-[state="hovered"]:bg-secondary-700 data-[state="hovered"]:border-secondary-700 data-[state="hovered"]:text-light-950 hover:bg-secondary-700 hover:border-secondary-700 hover:text-light-950':
              isSolid && !isDisabled,
            'data-[state="hovered"]:bg-secondary-300 data-[state="hovered"]:border-secondary-300 data-[state="hovered"]:text-dark-950 hover:bg-secondary-300 hover:border-secondary-300 hover:text-dark-950':
              !isSolid && !isInline && !isDisabled,
            'hover:text-dark-900 data-[state="hovered"]:text-dark-900': isInline && !isDisabled
          }
    );
  }

  if (color === 'neutral' || color === 'tertiary' || color === 'callout') {
    return classNames(
      baseClasses,
      isActive
        ? {
            'bg-neutral-300 border-neutral-300': !isInline && !isDisabled,
            'bg-secondary-400': isInline && !isDisabled
          }
        : {
            'bg-neutral-50 text-dark-900': isSolid && !isDisabled,
            'border-neutral-50': isSolid && !isDisabled,
            'border-dark-200': isOutline && !isDisabled,

            'data-[state="hovered"]:bg-neutral-200 data-[state="hovered"]:border-neutral-200 hover:bg-neutral-200 hover:border-neutral-200':
              weight !== 'inline' && !isDisabled
          }
    );
  }

  if (color === 'destructive') {
    return classNames(
      baseClasses,
      isActive
        ? {
            'bg-destructive-700 border-destructive-700 text-light-950': isSolid && !isDisabled,
            'bg-destructive-500 border-destructive-500 text-light-950':
              !isSolid && !isInline && !isDisabled,
            'text-destructive-500': isInline && !isDisabled
          }
        : {
            'bg-destructive-500 text-light-950': isSolid && !isDisabled,
            'bg-transparent text-destructive-500': !isSolid && !isDisabled,
            'border-destructive-500': (isSolid || isOutline) && !isDisabled,

            'data-[state="hovered"]:bg-destructive-600 data-[state="hovered"]:border-destructive-600 data-[state="hovered"]:text-light-950 hover:bg-destructive-600 hover:border-destructive-600 hover:text-light-950':
              isSolid && !isDisabled,
            'data-[state="hovered"]:bg-destructive-500/5 data-[state="hovered"]:border-destructive-500 data-[state="hovered"]:text-destructive-500 hover:bg-destructive-500/5 hover:border-destructive-500 hover:text-destructive-500':
              !isSolid && !isInline && !isDisabled
          }
    );
  }

  if (color === 'constructive') {
    return classNames(
      baseClasses,
      isActive
        ? {
            'bg-constructive-700 border-constructive-700 text-light-950': isSolid && !isDisabled,
            'bg-constructive-500 border-constructive-500 text-light-950':
              !isSolid && !isInline && !isDisabled,
            'text-constructive-500': isInline && !isDisabled
          }
        : {
            'bg-constructive-500 text-light-950': isSolid && !isDisabled,
            'bg-transparent text-constructive-500': !isSolid && !isDisabled,
            'border-constructive-500': (isSolid || isOutline) && !isDisabled,

            'data-[state="hovered"]:bg-constructive-600 data-[state="hovered"]:border-constructive-600 data-[state="hovered"]:text-light-950 hover:bg-constructive-600 hover:border-constructive-600 hover:text-light-950':
              isSolid && !isDisabled,
            'data-[state="hovered"]:bg-constructive-500/5 data-[state="hovered"]:border-constructive-500 data-[state="hovered"]:text-constructive-500 hover:bg-constructive-500/5 hover:border-constructive-500 hover:text-constructive-500':
              !isSolid && !isInline && !isDisabled
          }
    );
  }

  if (color == 'white') {
    return classNames(
      baseClasses,
      isActive
        ? { 'bg-primary-900 border-primary-900 text-white': !isInline && !isDisabled }
        : {
            'bg-white text-dark-900': isSolid && !isDisabled,
            'border-neutral-50': isSolid && !isDisabled,
            'border-primary-800/20': isOutline && !isDisabled,

            'data-[state="hovered"]:bg-neutral-200 data-[state="hovered"]:border-neutral-200 hover:bg-neutral-200 hover:border-neutral-200':
              weight !== 'inline' && !isDisabled
          }
    );
  }

  if (color == 'default') {
    if (isDisabled) {
      return 'bg-gray-100 text-gray-900 ring-1 ring-inset ring-gray-300';
    } else {
      return 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50';
    }
  }
}
