import React from 'react';
import { Heading, HeadingProps, Text } from 'react-aria-components';
import { classNames } from '../../utils/classNames';

export type ModalHeadingProps = HeadingProps;

export const ModalHeading: React.FC<ModalHeadingProps> = (props) => (
  <Heading
    slot='title'
    {...props}
    className={classNames(
      'text-base font-medium leading-6 text-gray-900 truncate',
      props.className
    )}
  />
);
export const ModalSubHeading = (props) => (
  <Text slot='subtitle' className={'text-gray-600 text-sm'}>
    {props.children}
  </Text>
);
