import React, { ReactNode } from 'react';
import { classNames } from '../../utils/classNames';

export interface ModalHeaderProps extends React.ComponentProps<'div'> {
  absolute?: boolean;
}

export const ModalHeader: React.FC<ModalHeaderProps> = (props) => {
  const { absolute, className, ...rest } = props;

  return (
    <div
      className={classNames(
        { 'absolute right-0 top-0 z-50': absolute },
        'flex pt-4 px-4 sm:pt-6 sm:px-6 justify-between items-start',
        className
      )}
      {...rest}
    />
  );
};
