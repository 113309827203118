import { composeRenderProps } from 'react-aria-components';
import { tv } from 'tailwind-variants';
import { classNames } from './classNames';

export const focusRing = tv({
  base: 'outline outline-primary-500 dark:outline-primary-500 forced-colors:outline-[Highlight] outline-offset-2',
  variants: {
    isFocusVisible: {
      false: 'outline-0',
      true: 'outline-2'
    }
  }
});

export function composeTailwindRenderProps<T>(
  className: string | ((v: T) => string) | undefined,
  tw: string
): string | ((v: T) => string) {
  return composeRenderProps(className, (className) => classNames(tw, className));
}

export type ComposeAnimationVariantConfig = string[];
export const composeAnimationVariants = (base: any, config: ComposeAnimationVariantConfig) =>
  config?.map((className, i) => ({
    ...base,
    isEntering: i === 0,
    isExiting: i === 1,
    className
  }));
