import { PlusIcon } from '@heroicons/react/16/solid';
import React, { useMemo } from 'react';
import { classNames } from '../../utils';
import { AvatarStack } from '../Avatar';
import { Icon } from '../Icon';
import { IMenuItem } from '../Menu';
import { DropdownLabel } from './DropdownLabel';

export interface SelectLabelProps {
  open?: boolean;
  prefix?: React.ReactNode;
  placeholder?: React.ReactNode;
  multiple?: boolean;
  selectedProperties: IMenuItem[];
}

export const DropdownMenuLabel: React.FC<SelectLabelProps> = React.memo((props) => {
  const { open, placeholder, prefix, selectedProperties, multiple } = props;

  const label =
    placeholder && !selectedProperties.length ? (
      <span className={open ? 'inherit' : 'text-gray-500'}>{placeholder}</span>
    ) : multiple ? (
      <div className={'flex items-center truncate gap-x-1.5'}>
        <span className={'truncate'}>{selectedProperties?.[0]?.label}</span>
        {selectedProperties.length > 1 ? (
          <div className={'flex items-center gap-x-px text-xs font-medium text-gray-500'}>
            <PlusIcon className={'w-2.5 h-2.5'} />
            <span>{selectedProperties.length - 1} more</span>
          </div>
        ) : null}
      </div>
    ) : (
      selectedProperties[0]?.label
    );

  const usedIcons = useMemo(
    () =>
      Array.from(
        new Set(
          selectedProperties?.map((prop) => prop.selectedIcon || prop.icon).filter(Boolean) || []
        )
      ).filter(Boolean),
    [selectedProperties]
  );

  return (
    <div className={'flex items-center truncate'}>
      {usedIcons.length ? (
        <AvatarStack
          size={'xs'}
          total={usedIcons?.length}
          limit={5}
          direction={'ltr'}
          className={'mr-[7px]'}
          defaultColor={
            usedIcons.length > 1
              ? open
                ? 'bg-primary-900'
                : 'group-hover/button:bg-neutral-200 bg-white'
              : ''
          }
          avatarClassName={classNames('outline-0 rounded-xs transition-all shadow-none')}
        >
          {usedIcons
            .slice(0, 5)
            .reverse()
            .map((icon, i) => (
              <Icon key={i} icon={icon} iconProps={{ className: 'w-3.5 h-3.5' }} />
            ))}
        </AvatarStack>
      ) : null}
      <DropdownLabel prefix={prefix} label={label} isActive={open} />
    </div>
  );
});
