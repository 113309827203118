import React, { ComponentProps } from 'react';
import { classNames } from '../../utils';

export type BadgeColor =
  | 'info'
  | 'constructive'
  | 'callout'
  | 'destructive'
  | 'neutral'
  | 'light'
  | 'dark';

export type BadgeType = 'default' | 'rounded';

export type BadgeSize = 'sm' | 'base';

export interface BadgeProps extends ComponentProps<'div'> {
  /**
   * Classname for the badge
   * @type string
   * @optional
   */
  className?: string;
  /**
   * Color of the badge
   * @type 'callout' | 'primary' | 'neutral'
   * @default 'neutral'
   * @optional
   */
  color?: BadgeColor;
  type?: BadgeType;
  size?: BadgeSize;
}

export const Badge: React.FC<BadgeProps> = (props) => {
  const { color, type = 'default', size = 'base', ...rest } = props;

  return (
    <div
      {...rest}
      className={classNames(
        'flex min-w-fit w-fit rounded py-1 px-2 text-xs whitespace-nowrap border truncate font-medium text-dark-950 cursor-default',
        {
          'bg-info-200 border-info-200 ': color === 'info',
          'bg-[#F4EED5] border-[#F4EED5] ': color === 'callout',
          'bg-neutral-200 border-neutral-200 ': color === 'neutral',
          'bg-[#C6EBD3] border-[#C6EBD3] ': color === 'constructive',
          'bg-destructive-200 border-destructive-200 ': color === 'destructive',
          'bg-transparent border-dark-400 ': color === 'light',
          'bg-dark-700 border-dark-100 text-light-950': color === 'dark'
        },
        {
          'rounded-full': type === 'rounded',
          '': type === 'default'
        },
        {
          'text-[0.6rem]': size === 'sm',
          'text-xs': size === 'base'
        },
        props.className
      )}
    />
  );
};
