import React from 'react';
import { Label, LabelProps, Text, TextProps } from 'react-aria-components';
import { FormFieldHelp } from './FormFieldHelp';
import { classNames } from '../../utils';

export interface FormFieldProps {
  id?: string;
  label?: React.ReactNode;
  helpText?: React.ReactNode;
  action?: React.ReactNode;
  errors?: (string | null | undefined | false)[];
  Header?: React.FC<FormFieldHeaderProps>;
  className?: string;
  children: React.ReactNode;
}

export const FormField: React.FC<FormFieldProps> = ({ Header = FormFieldHeader, ...props }) => (
  <div className={classNames('relative flex flex-col gap-y-1.5', props.className)}>
    {props.label || props.helpText || props.action ? (
      <Header>
        <FormFieldHeading>
          {props.label ? <FormFieldLabel htmlFor={props.id}>{props.label}</FormFieldLabel> : null}
          {props.helpText ? <FormFieldHelp>{props.helpText}</FormFieldHelp> : null}
        </FormFieldHeading>
        {props.action}
      </Header>
    ) : null}
    <div className='flex flex-col gap-y-1.5'>{props.children}</div>
    {props.errors?.filter(Boolean).length ? (
      <div className={'flex flex-col gap-y-1'}>
        {props.errors.filter(Boolean).map((msg, i) => (
          <FormFieldError key={i} className='text-sm text-red-600'>
            {msg}
          </FormFieldError>
        ))}
      </div>
    ) : null}
  </div>
);

type FormFieldHeaderProps = React.ComponentProps<'div'>;
const FormFieldHeader: React.FC<FormFieldHeaderProps> = (props) => (
  <div
    {...props}
    className={classNames(props.className, 'flex gap-x-1.5 items-center justify-between')}
  />
);
const FormFieldHeading: React.FC<FormFieldHeaderProps> = (props) => (
  <div {...props} className={classNames(props.className, 'flex gap-x-1.5 items-center')} />
);

type FormFieldErrorProps = TextProps;
const FormFieldError: React.FC<FormFieldErrorProps> = (props) => (
  <Text
    role='alert'
    slot='errorMessage'
    {...props}
    className={classNames(props.className, 'text-sm text-red-600')}
  />
);

type FormFieldLabelProps = LabelProps;
const FormFieldLabel: React.FC<FormFieldLabelProps> = (props) => (
  <Label
    {...props}
    className={classNames(
      props.className,
      'block text-sm font-normal text-gray-800 dark:text-[rgb(250,250,250)]'
    )}
  />
);
