import React from 'react';
import type { Key } from 'react-aria-components';
import { MenuSeparatorPlaceholder } from './MenuSeparator';
import type { ButtonProps } from '../Button';

export type MenuKey = Key;
export interface IMenuItem {
  id?: MenuKey;
  group?: string;
  label?: React.ReactNode;
  extra?: React.ReactNode;
  searchKey?: string;
  icon?: React.ReactNode | React.FC<{ className?: string }>;
  iconProps?: ButtonProps['iconProps'];
  selectedIcon?: React.ReactNode | React.FC<{ className?: string }>;
  tooltip?: React.ReactNode;
  current?: boolean;
  disabled?: boolean;
  buttonProps?: Partial<ButtonProps>;
  onClick?: (e: React.SyntheticEvent) => void;
  onPress?: (e: React.SyntheticEvent) => void;
  to?: string;
}

export type MenuItems = (IMenuItem | typeof MenuSeparatorPlaceholder)[];

export type IMenuItemsGroup =
  | typeof MenuSeparatorPlaceholder
  | {
      header?: React.ReactNode;
      items: MenuItems;
      separator?: null;
      hidden?: boolean;
      multiple?: boolean;
      expanded?: boolean;
      collapsible?: boolean;
      className?: string;
    };

export type MenuSelectionMode = 'single' | 'multiple' | 'group-multiple';
