import React from 'react';
import {
  Checkbox as BasicCheckbox,
  CheckboxProps as BasicCheckboxProps
} from 'react-aria-components';
import { classNames } from '../../utils';
import { ControlTooltipProps } from '../Button/types';
import { useToolTip } from '../Tooltip';
import { checkStyles } from './checkStyles';
import { Tooltip } from '../Tooltip/Tooltip';

export type CheckboxProps = BasicCheckboxProps & {
  color?: 'primary' | 'secondary' | 'tertiary';
  size?: 'sm' | 'md';
  groupHover?: boolean;
  wrapperClassName?: string;
  tickClassName?: string;
  tip?: React.ReactNode | Partial<ControlTooltipProps>;
  tipProps?: Partial<ControlTooltipProps>;
  children?: string | React.ReactNode;
};

export const Checkbox: React.FC<CheckboxProps> = (props) => {
  const {
    wrapperClassName,
    tickClassName,
    color = 'secondary',
    size = 'md',
    groupHover = Boolean(props.children),
    tip,
    tipProps,
    ...rest
  } = props;

  const tooltip = useToolTip(tip, tipProps);

  return (
    <>
      <BasicCheckbox {...rest}>
        {({ isSelected, isDisabled }) => {
          return (
            <div
              data-tooltip-id={tooltip?.id}
              className={classNames(
                'flex items-center gap-x-1.5',
                props.isDisabled ? 'opacity-50' : 'cursor-pointer',
                groupHover ? 'group/checkbox' : '',
                wrapperClassName
              )}
            >
              <div
                className={classNames(
                  checkStyles({
                    size,
                    color,
                    isSelected,
                    isDisabled
                  }),
                  tickClassName
                )}
              >
                {isSelected ? (
                  <svg
                    className={classNames('fill-white', {
                      'w-2 h-2': size === 'sm',
                      'w-3 h-3': size === 'md'
                    })}
                    viewBox='0 0 20 20'
                  >
                    <path d='M0 11l2-2 5 5L18 3l2 2L7 18z' />
                  </svg>
                ) : null}
              </div>

              {typeof props.children === 'string' ? (
                <span
                  className={classNames('font-medium', {
                    'text-xs': size === 'sm',
                    'text-sm': size === 'md'
                  })}
                >
                  {props.children}
                </span>
              ) : (
                props.children
              )}
            </div>
          );
        }}
      </BasicCheckbox>
      {tooltip ? (
        <Tooltip key={rest.isSelected ? 'checked' : 'unchecked'} {...tooltip}>
          {tooltip.content}
        </Tooltip>
      ) : null}
    </>
  );
};
