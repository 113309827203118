import React from 'react';
import { Dialog, DialogProps } from 'react-aria-components';
import { ModalProps } from './types';
import { classNames } from '../../utils/classNames';

export interface ModalDialogProps extends DialogProps {
  role?: DialogProps['role'];
  position: ModalProps['position'];
  overflowHidden: ModalProps['overflowHidden'];
}

export const ModalDialog: React.FC<ModalDialogProps> = (props) => {
  const { position, overflowHidden, role = 'alertdialog', className, ...rest } = props;

  return (
    <Dialog
      role={role}
      className={classNames(
        'flex flex-col items-stretch outline-none relative min-h-full bg-white shadow-lg',
        position === 'center' ? 'rounded-lg' : '',
        'overflow-y-auto',
        overflowHidden ? 'sm:overflow-hidden' : '',
        'max-h-[100vh] max-w-[100vw]',
        'm-2 sm:m-4 sm:max-h-[calc(100vh-2rem)] sm:max-w-[calc(100vw-2rem)]',
        className
      )}
      {...rest}
    >
      {props.children}
    </Dialog>
  );
};
