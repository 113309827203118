import { nanoid } from 'nanoid';
import React, { Children, useId, useState } from 'react';
import { Avatar, AvatarProps } from './AvatarInitials';
import { classNames } from '../../utils/classNames';
import { Tooltip } from '../Tooltip';

export type AvatarStackProps = {
  /**
   * Size of the avatar
   * @default 'lg'
   * @type 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'
   * @optional
   */
  size?: AvatarProps['size'];
  /**
   * Total number of avatars
   * @type number
   * @optional
   */
  total?: number;
  /**
   * Maximum number of avatars to display
   * @type number
   * @optional
   * @default 5
   */
  limit?: number;
  /**
   * Classname for the container
   * @type string
   * @optional
   */
  className?: string;
  /**
   * Classname for the avatar
   * @type string
   * @optional
   */
  avatarClassName?: string;
  /**
   * Default color for the avatar
   * @type string
   * @optional
   * @default 'bg-neutral-100'
   */
  defaultColor?: string;
  /**
   * Priority direction
   * @type 'rtl' | 'ltr'
   * @optional
   * @default 'rtl'
   */
  direction?: 'rtl' | 'ltr';
  /**
   * Component to use for the avatar
   * @type React.FC<Partial<AvatarProps>>
   * @optional
   * @default Avatar
   */
  Component?: React.FC<Partial<AvatarProps>>;
  /**
   * Children to render
   * @type React.ReactNode
   * @optional
   */
  children: any;
};

export const AvatarStack: React.FC<AvatarStackProps> = (props) => {
  const {
    size = 'lg',
    className,
    avatarClassName,
    Component = Avatar,
    children,
    limit = 5,
    direction = 'rtl',
    defaultColor = 'bg-neutral-100'
  } = props;

  const arrayChildren = Children.toArray(children).filter(Boolean);

  const limitedArrayChildren =
    arrayChildren.length > limit ? arrayChildren.slice(0, limit) : arrayChildren;

  const { total = arrayChildren.length } = props;

  const left = total ? total - limit : 0;

  return (
    <div
      data-testid='avatar-stack'
      className={classNames('relative inline-flex pl-px', className)}
      style={{ direction }}
    >
      {left > 0 ? (
        <Component
          size={size}
          className={classNames(
            'z-10 outline outline-white shadow-md',
            direction === 'rtl' ? '-ml-0.5' : '-mrl-0.5',
            avatarClassName
          )}
          defaultColor={defaultColor}
        >
          <span className={'text-[10px] font-medium'} style={{ direction: 'ltr' }}>
            +{Math.min(99, left)}
          </span>
        </Component>
      ) : null}
      {Children.map(limitedArrayChildren, (child, index) => {
        // @ts-expect-error Property 'props' does not exist on type 'string'.
        const id = child.props.id;

        return (
          <AvatarStackItem
            id={id}
            key={id || index}
            index={index}
            size={size}
            direction={direction}
            limitedArrayChildren={limitedArrayChildren}
            defaultColor={defaultColor}
            className={avatarClassName}
            child={child}
            tooltip={child.props.tooltip}
          >
            {child}
          </AvatarStackItem>
        );
      })}
    </div>
  );
};

const AvatarStackItem = (props) => {
  const {
    id,
    index,
    size,
    className,
    defaultColor,
    limitedArrayChildren,
    direction,
    Component = Avatar,
    tooltip,
    children,
    ...rest
  } = props;

  const tooltipId = useId();

  return (
    <React.Fragment>
      <Component
        id={id}
        size={size}
        className={classNames(
          'z-10 outline outline-white shadow-md',
          { 'outline-[1px]': size === 'xs' },
          direction === 'rtl'
            ? index === limitedArrayChildren.length - 1
              ? ''
              : '-ml-1'
            : index === limitedArrayChildren.length - 1
              ? ''
              : '-mr-1',
          className
        )}
        defaultColor={defaultColor}
        data-testid={`avatar-${id || index}`}
        data-tooltip-id={`avatar-${id || index}-${tooltipId}`}
        {...rest}
      >
        {children}
      </Component>

      <Tooltip
        id={`avatar-${id || index}-${tooltipId}`}
        positionStrategy={'fixed'}
        style={{ direction: 'ltr' }}
      >
        {/* @ts-expect-error Property 'props' does not exist on type 'string'. */}
        {tooltip}
      </Tooltip>
    </React.Fragment>
  );
};
