'use client';
import { NoSymbolIcon } from '@heroicons/react/20/solid';
import { motion } from 'framer-motion';
import React, { ComponentProps } from 'react';
import { tv } from 'tailwind-variants';

export interface AlertProps extends ComponentProps<'div'> {
  /**
   * The size of the alert.
   * @default 'sm'
   * @optional
   */
  size?: 'sm' | 'md' | 'lg';
  /**
   * The color of the alert.
   * @default 'callout'
   * @optional
   */
  color?: 'neutral' | 'callout';
  /**
   * The weight of the alert.
   * @default 'solid'
   * @optional
   */
  weight?: 'outline' | 'ghost' | 'solid';
  /**
   * The symbol to display in the alert.
   * @optional
   * @default NoSymbolIcon
   */
  symbol?: React.FC<{ className?: string }>;
  /**
   * The content of the alert.
   * @optional
   */
  children: React.ReactNode | React.ReactNode[] | string;
}

export const Alert: React.FC<AlertProps> = (props) => {
  const {
    size,
    color,
    weight,
    className,
    children,
    symbol: Symbol = NoSymbolIcon,
    ...rest
  } = props;

  return (
    <motion.div
      className={alertOuter(props)}
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: 'fit-content' }}
      exit={{ opacity: 0, height: 0 }}
      {...rest}
    >
      <div className={alertInner(props)}>
        <div className={alertSymbolOuter(props)}>
          <Symbol className={alertSymbol(props)} />
        </div>
        <div className={alertContent(props)}>
          {typeof children === 'string' ? <p>{children}</p> : children}
        </div>
      </div>
    </motion.div>
  );
};

const alertOuter = tv({
  base: 'relative rounded overflow-hidden',
  variants: {
    size: {
      sm: '',
      md: '',
      lg: ''
    },
    color: {
      neutral: '',
      callout: ''
    },
    weight: {
      ghost: '',
      outline: '',
      solid: ''
    }
  },
  compoundVariants: [
    {
      color: 'neutral',
      weight: 'ghost',
      className: 'bg-none border-none'
    },
    {
      color: 'neutral',
      weight: 'outline',
      className: 'bg-none border border-gray-100'
    },
    {
      color: 'neutral',
      weight: 'solid',
      className: 'bg-gray-100 border border-gray-100'
    },
    {
      color: 'callout',
      weight: 'ghost',
      className: 'bg-none border-none'
    },
    {
      color: 'callout',
      weight: 'outline',
      className: 'bg-none border border-callout-200'
    },
    {
      color: 'callout',
      weight: 'solid',
      className: 'bg-[#F4EED5] border-[#F4EED5]'
    }
  ],
  defaultVariants: {
    size: 'sm',
    weight: 'solid',
    color: 'callout'
  }
});

const alertInner = tv({
  base: 'flex items-start',
  variants: {
    size: {
      sm: 'py-3 px-4 gap-2.5',
      md: 'py-3 px-4 gap-3',
      lg: 'py-3 px-4 gap-3'
    }
  },
  defaultVariants: {
    size: 'sm',
    weight: 'solid',
    color: 'callout'
  }
});

const alertSymbolOuter = tv({
  base: 'flex items-center justify-center',
  variants: {
    size: {
      sm: 'min-h-7',
      md: 'min-h-8',
      lg: 'min-h-9'
    }
  },
  defaultVariants: {
    size: 'sm',
    weight: 'solid',
    color: 'callout'
  }
});

const alertSymbol = tv({
  base: 'shrink-0',
  variants: {
    color: {
      callout: 'text-callout-800',
      neutral: 'text-gray-500'
    },
    size: {
      sm: 'h-4 w-4',
      md: 'h-5 w-5',
      lg: 'h-6 w-6'
    },
    weight: {
      ghost: '',
      outline: '',
      solid: ''
    }
  },
  compoundVariants: [
    {
      color: 'neutral',
      weight: 'ghost',
      className: 'text-gray-400'
    }
  ],
  defaultVariants: {
    size: 'sm',
    weight: 'solid',
    color: 'callout'
  }
});

const alertContent = tv({
  base: 'flex flex-1 items-center justify-center',
  variants: {
    color: {
      neutral: 'text-gray-750',
      callout: 'text-gray-750'
    },
    size: {
      sm: 'text-xs min-h-7',
      md: 'text-sm min-h-8',
      lg: 'text-md min-h-9'
    },
    weight: {
      ghost: '',
      outline: '',
      solid: ''
    }
  },
  compoundVariants: [
    {
      color: 'neutral',
      weight: 'ghost',
      className: 'text-gray-400'
    }
  ],
  defaultVariants: {
    size: 'sm',
    weight: 'solid',
    color: 'callout'
  }
});
