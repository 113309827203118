'use client';
import React from 'react';

import styles from './styles.module.css';
import { SuperAdsIcon } from '../../icons/SuperAdsIcon';
import { UiSize } from '../../types';
import { classNames } from '../../utils/classNames';
import { normalizeSize } from '../../utils/size';
import { Spinner } from '../Spinner/Spinner';

export type LoaderProps = {
  size?: UiSize;
  className?: string;
};

export const Loader: React.FC<LoaderProps> = (props) => {
  const { className } = props;
  const size = normalizeSize(props.size || 'small');

  return (
    <div
      className={classNames(
        'flex shrink-0 grow-0 mx-auto text-center items-center justify-center',
        className
      )}
    >
      <Spinner />
    </div>
  );
};
